import { FC } from "react";
import { v4 as uuid } from "uuid";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import finkargo from "../../../../assets/images/home/carouselImg/finkargo.png";
import yuno from "../../../../assets/images/home/carouselImg/yuno.png";
import heru from "../../../../assets/images/home/carouselImg/heru.png";
import muncher from "../../../../assets/images/home/carouselImg/muncher.png";
import alima from "../../../../assets/images/home/carouselImg/alima.png";
import chiper from "../../../../assets/images/home/carouselImg/chiper.png";
import perfekto from "../../../../assets/images/home/carouselImg/perfekto.png";
import iki from "../../../../assets/images/home/carouselImg/iki.png";
import hitch from "../../../../assets/images/home/carouselImg/hitch.png";
import homie from "../../../../assets/images/home/carouselImg/homie.png";
import clara from "../../../../assets/images/home/carouselImg/clara.webp";
import merama from "../../../../assets/images/home/carouselImg/merama.webp";
import minka from "../../../../assets/images/home/carouselImg/minka.webp";
import mozper from "../../../../assets/images/home/carouselImg/mozper.webp";
import xepelin from "../../../../assets/images/home/carouselImg/xepelin.webp";

interface IItem {
  sizeBrand?: number;
}

const SliderCompanies: FC<IItem> = ({ sizeBrand }: IItem) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 2000,
    variableWidth: true,
  };
  const imgList = [
    finkargo,
    yuno,
    heru,
    muncher,
    alima,
    chiper,
    perfekto,
    iki,
    hitch,
    homie,
    clara,
    merama,
    minka,
    mozper,
    xepelin,
  ];
  const generateBanners = () => {
    return imgList.map((item) => {
      return (
        <div className="sizeContainerBrand" key={uuid()}>
          <img
            className="sizeImgBrand"
            style={{ maxHeight: sizeBrand ? `${sizeBrand}px` : "" }}
            alt="companiesImg"
            src={item}
          />
        </div>
      );
    });
  };
  return (
    <>
      <Slider className="h-full" {...settings}>
        {generateBanners()}
      </Slider>
    </>
  );
};
export default SliderCompanies;
